import React from 'react';
import { Provider, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
  useSearchParams,
  useLocation
} from 'react-router-dom';
import {IUser, login} from './api/Login';
import './App.scss';
import { Page } from './pages';
import Add from './pages/Add/Add';
import AddFamily from './pages/AddFamily/AddFamily';
import Admin from './pages/Admin/Admin';
import Home from './pages/Home/Home';
import Invitation from './pages/Invitation/Invitation';
import Login, { admins } from './pages/Login/Login';
import MusicPage from './pages/Music/Music';
import { RootState, store } from './store';
import Images from './pages/Images/Images';
import ImagesAdd from './pages/Images/ImagesAdd';
import AWS from 'aws-sdk';
import ImagesBox from './pages/Images/ImagesBox';
import Videos from './pages/Images/Videos';
import useUser from "./storage/useUser";
import {setUser} from "./reducer/userSlice";


const App: React.FC = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <MainPage />
      </Provider>
    </React.Fragment>
  )
}

export default App;

const ProtectedRoute = ({ user, children }) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const MainPage: React.FC = (props: any) => {
  const userRedux = useSelector((state: RootState) => state.globalUser);
  const user = useUser((state) => state.name);

  return (
    <Router>
      <Routes>
        <Route path={Page.Home} element={<Home />} />
        {/* <Route path={Page.HomeQR} element={<Home />} /> 
        <Route path={Page.HomeInv} element={<Home />} />
        <Route path={Page.Invitation} element={<Invitation />} />
        <Route path={Page.Music} element={<MusicPage />} /> 
        <Route
              path={Page.Images}
              element={
                  <ProtectedRoute user={user}>
                      <Images />
                  </ProtectedRoute>
              }
          />
        <Route path={Page.Images} element={<Images />} />
        <Route path={Page.ImagesAdd} element={<ImagesAdd />} />
        <Route path={Page.ImagesBox} element={<ImagesBox />} />
        <Route path={Page.Videos} element={<Videos />} /> */}
        {/*<Route*/}
        {/*  path={Page.Admin}*/}
        {/*  element={*/}
        {/*    <ProtectedRoute user={userRedux && admins.includes((userRedux as IUser).email)}>*/}
        {/*      <Admin />*/}
        {/*    </ProtectedRoute>*/}
        {/*  }*/}
        {/*/>*/}
        {/* <Route path={Page.Login} element={<Login />} />*/}

        {/* Fallback route */}
        <Route path="*" element={<Navigate to={Page.Home} />} />
      </Routes>
    </Router>
  )
}
