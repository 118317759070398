export let Images = {
}

export let HomeImages = {
    home_main_jpg: require('./img/Home/Home_main.jpeg'),
    home_main_webp: require('./img/Home/Home_main.webp'),
}

export let API = {
    // url: 'http://127.0.1.1:8081',
    url: 'https://esche2punkt0.site',
    version: '/v1'
}

export let Text = {
    Invitation_title_single: "Wir trauen uns - und möchten dich dabei haben!",
    Invitation_title: "Wir trauen uns - und möchten euch dabei haben!",
    Invitation_sub_single: "Am 12.08.2023 ist es soweit und wir werden uns das JA-Wort geben. Bitte lass uns bis zum 01.06.2023 wissen, ob du bei unserem großen Tag dabei sein wirst.",
    Invitation_sub: "Am 12.08.2023 ist es soweit und wir werden uns das JA-Wort geben. Bitte lasst uns bis zum 01.06.2023 wissen, ob ihr bei unserem großen Tag dabei sein werdet.",
    Change_invite_single: "Du kannst deine Angaben jederzeit bis zum 01.06.2023 ändern.",
    Change_invite: "Ihr könnt eure Angaben jederzeit bis zum 01.06.2023 ändern.",
    Home_1: "Ihr seid herzlich eingeladen!",
}

export let Links = {
    saalbau: "https://www.google.de/maps/place/Von-der-Kuhlen-Stra%C3%9Fe+35,+58642+Iserlohn/@51.3659236,7.6088424,17z/data=!3m1!4b1!4m6!3m5!1s0x47b93f390d4b5b11:0xa837bb8d90d7e0fa!8m2!3d51.3659203!4d7.6110311!16s%2Fg%2F11bw4b25vl",
    parkplatz_1: "https://www.google.de/maps/place/Marktstra%C3%9Fe+34,+58642+Iserlohn/@51.3660746,7.6111306,19z/data=!3m1!4b1!4m5!3m4!1s0x47b93f39105b668f:0x8bb0d4104a40b732!8m2!3d51.3660738!4d7.6116778",
    parkplatz_2: "https://www.google.de/maps/place/Hagener+Str.+40,+58642+Iserlohn/@51.3653876,7.6128299,19.25z/data=!4m6!3m5!1s0x47b93f38f8d070fb:0xa4217d4381bc2be6!8m2!3d51.3653009!4d7.6128329!16s%2Fg%2F11bw3ygw2s",
    gasthof_denniger: "https://www.google.de/maps/place/Auf+dem+Birnbaum+2,+58642+Iserlohn/@51.3704168,7.6242593,19z/data=!4m6!3m5!1s0x47b93f422506fc27:0xb25f32f466043222!8m2!3d51.3704873!4d7.6247261!16s%2Fg%2F11bw40rqch",
    dorfkrug: "https://www.google.de/maps/place/Pension+Zum+Dorfkrug/@51.3706771,7.6301789,17z/data=!3m2!4b1!5s0x47b93f5d402e30b1:0x7daa4b61915e117c!4m9!3m8!1s0x47b93f5d158dab31:0x23600a05f0f003c6!5m2!4m1!1i2!8m2!3d51.3706738!4d7.6323676!16s%2Fg%2F1vyk1lq3",
    kirche_villigst: "https://www.google.de/maps/place/Villigster+Str.+43,+58239+Schwerte/@51.43494,7.5941284,18.25z/data=!4m6!3m5!1s0x47b93e287c55565f:0xe0d8d51483638714!8m2!3d51.4351521!4d7.5950029!16s%2Fg%2F11c140_b3t"
}
