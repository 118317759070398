import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../api/Login";

const initialState: IUser | null = null;

export const familySlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return {...action.payload};
    },
  },
});

export const { setUser } = familySlice.actions;
export default familySlice.reducer;
