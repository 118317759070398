import { createSlice } from "@reduxjs/toolkit";
import { IGetFamilyById } from "../api/Family";

const initialState: IGetFamilyById = {} as IGetFamilyById;

export const familySlice = createSlice({
  name: "family",
  initialState,
  reducers: {
    setFamily: (state, action) => {
      return {...action.payload};
    },
  },
});

export const { setFamily } = familySlice.actions;
export default familySlice.reducer;
