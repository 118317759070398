import axios from 'axios';
import { API } from '../assets/assets';

export interface IUser {
    name: string,
    id: string,
}

// export const login = (email: string, password: string) => {
//     return axios({
//         method: 'POST',
//         url: API.url + API.version + '/login',
//         data: {
//             email,
//             password
//         }
//     }).then((res) => {
//         return res.data as IUser | null;
//     }).catch((e) =>{
//         return null;
//     })
// }
export const login = (name: string, password: string) => {
    return axios({
        method: 'POST',
        url: API.url + API.version + '/login-normal',
        data: {
            name,
            password
        }
    }).then((res) => {
        // console.log(res.data);
        return res.data as {isValid: boolean} | null;
    }).catch((e) =>{
        return null;
    })
}
