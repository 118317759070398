import React, { useEffect, useRef } from "react";
import { HomeImages, Images } from "../../assets/assets";
import Countdown, { CountdownRenderProps } from 'react-countdown';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import './Background.scss';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import home2JPG from '../../assets/img/Home/Home_2.jpg';
import home2WEBP from '../../assets/img/Home/Home_2.webp';
import home3JPG from '../../assets/img/Home/Home_3.jpg';
import home3WEBP from '../../assets/img/Home/Home_3.webp';

// import entire SDK

interface IBackgroundProps {
  inviteButtonCallback?: () => void;
  isInLimit: boolean;
  date: Date;
  photo: {
    isShowing: boolean,
    uploadClick: () => void,
    downloadClick: () => void,
  }
}

const homePictures: {webp: string, jpg: string}[] = [
  {
    webp: HomeImages.home_main_webp,
    jpg: HomeImages.home_main_jpg
  },
]

const Background: React.FC<IBackgroundProps> = (props) => {
  const renderCountdown = (cdrp: CountdownRenderProps) => {
    return (
      <div className="countdown--container">
        <div className="countdown--column">
          <div>{cdrp.days}</div>
          <span>{cdrp.days > 1 ? 'Tage' : 'Tag'}</span>
        </div>
        <div className="countdown--column">
          <div>{cdrp.hours}</div>
          <span>STD</span>
        </div>
        <div className="countdown--column">
          <div>{cdrp.minutes}</div>
          <span>MIN</span>
        </div>
        <div className="countdown--column">
          <div>{cdrp.seconds}</div>
          <span >SEK</span>
        </div>
      </div>
    )
  }

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const delay = 5000;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    (timeoutRef.current as any) = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === homePictures.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);


  return (
    <div className="bg--container">
      <div className="slideshow">
        <div
          className="slideshowSlider"
        >
          {homePictures.map((pic, index) => {
            return (
              <picture key={`Picture_home_${index}`}>
                <source srcSet={pic.webp} type="image/webp" />
                <source srcSet={pic.jpg} type="image/jpg" />
                <img className="bg--img slide" src={pic.webp} alt="bg" />
              </picture>
            )
          })}
        </div>
      </div>

      {/* <Carousel
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop
        autoPlay
        swipeable={false}
        showStatus={false}
        transitionTime={1000}
        selectedItem={-1}
      >
        {homePictures.map((pic, index) => {
          return (
            <picture key={`Picture_home_${index}`}>
              <source srcSet={pic.webp} type="image/webp" />
              <source srcSet={pic.jpg} type="image/jpg" />
              <img className="bg--img" src={pic.webp} alt="bg" loading="lazy" />
            </picture>
          )
        })}
      </Carousel> */}
      <div className="bg--text-container">
          {props.photo.isShowing ?
              <div className="bg--invite-button" onClick={props.photo.uploadClick}>
                  <span className="bg--invite-button--text font-face-opensans-li">
                    {'Fotos hinzufügen'}
                      <DoubleArrowIcon className="double-arrow hovering" />
                  </span>
              </div> : null }
              {props.photo.isShowing ?
              <div className="bg--invite-button" onClick={props.photo.downloadClick}>
                  <span className="bg--invite-button--text font-face-opensans-li">
                    {'Fotos herunterladen / anschauen'}
                      <DoubleArrowIcon className="double-arrow hovering" />
                  </span>
              </div> : null }
        {/*<Countdown*/}
        {/*  date={props.date}*/}
        {/*  renderer={cdProps =>*/}
        {/*    <div>*/}
        {/*      {props.photo.isShowing ?*/}
        {/*        <div className="bg--invite-button" onClick={props.photo.photosClick}>*/}
        {/*          <span className="bg--invite-button--text font-face-opensans-li">*/}
        {/*            {'Fotos hinzufügen / anschauen'}*/}
        {/*            <DoubleArrowIcon className="double-arrow hovering" />*/}
        {/*          </span>*/}
        {/*        </div> : null }*/}
        {/*      {renderCountdown(cdProps)}*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*/>*/}
      </div>
    </div>
  )
}

export default Background;
