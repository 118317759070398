import { configureStore } from "@reduxjs/toolkit";
import familySlice from "../reducer/familySlice";
import userSlice from "../reducer/userSlice";

export const store = configureStore({
  reducer: {
    globalFamily: familySlice,
    globalUser: userSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
