import { Typography } from "@mui/material";
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getFamilyById, IGetFamilyById } from "../../api/Family";
import { HomeImages, Images, Links, Text } from "../../assets/assets";
import Background from "../../components/Background/Background";
import { Page } from "../../pages";
import { setFamily } from "../../reducer/familySlice";
import { RouteHelper } from "../../RouteHelper";
import { RootState } from "../../store";
import moment from 'moment';
import './Home.scss';
import DayPlanElement from "../../components/DayPlanElement/DayPlanElement";
import ChurchIcon from '@mui/icons-material/Church';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import {login} from "../../api/Login";
import useUser from "../../storage/useUser";

const Home: React.FC = () => {
    // const [familyData, setFamilyData] = React.useState<IGetFamilyById>();
    const { id, pw } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const limitDate = moment('2024-07-05')
    const limitDate = moment()
    const isInLimit = moment().isSameOrBefore(limitDate)
    const photosTime = moment().isSameOrAfter(limitDate);
    const setUser = useUser((state) => state.setName);

    useEffect(() => {
        if (id && pw) {
            login(id, pw).then((data) => {
                setUser('esche2punkt0');
                setTimeout(() => {
                    navigate(Page.Images);
                }, 500);
            })
        }
    }, []);

    useEffect(() => {
      window.scrollTo(0, 0);
      if (id) {
        getFamilyById(id!).then((res: IGetFamilyById) => {
          dispatch(setFamily(res));
        })
      }
    }, [])

    return (
        <div className="home">
            <Background
              isInLimit={isInLimit}
              date={limitDate.toDate()}
              photo={{
                  isShowing: photosTime,
                  // photosClick: () => navigate(Page.Images)
                  uploadClick: () => window.open("https://www.dropbox.com/request/GWEvWaKi3zF2UeSpwtAb", "_blank", "noreferrer"),
                  downloadClick: () => window.open("https://www.dropbox.com/scl/fo/r52jam5pp3w6tokbojxmg/AHfTREkzJo1UTi7F3Wpyy4k?rlkey=nx3cyh63yalvkcsgqrpcuc1zs&st=c5jti9up&dl=0", "_blank", "noreferrer"),
              }}
            />
        </div>
    )
}

export default Home;
