import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist, PersistOptions } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { PersistStorage, StateStorage, StorageValue } from 'zustand/middleware/persist';

class ZustandHelper {
    static createPersistentStore<T>(name: string, set: StateCreator<T, [['zustand/immer', never]], [], T>, persistOptions?: Omit<PersistOptions<T>, 'name' | 'storage'>) {
        return createWithEqualityFn(persist(immer<T>(set), { name, storage: this.createEnhancedJSONStorage(() => localStorage), ...persistOptions }), shallow);
    }

    static createInMemoryStore<T>(set: StateCreator<T, [['zustand/immer', never]], [], T>) {
        return createWithEqualityFn(immer<T>(set), shallow);
    }

    /**
     * Creates an enhanced JSONStorage (compared to zustand/middleware#createJSONStorage) allowing the storage of Maps, Sets
     * and Dates, which are otherwise not serializable.
     */
    private static createEnhancedJSONStorage<S>(getStorage: () => StateStorage): PersistStorage<S> | undefined {

        const replacer = (key: string, value: any) => {
            if (value instanceof Map) {
                return { __type: 'Map', value: Object.fromEntries(value) };
            }
            if (value instanceof Set) {
                return { __type: 'Set', value: Array.from(value) };
            }
            if (value instanceof Date) {
                return { __type: 'Date', value: value.getTime() };
            }
            return value;
        };

        const reviver = (key: string, value: any) => {
            if (value?.__type === 'Map') {
                return new Map(Object.entries(value.value));
            }
            if (value?.__type === 'Set') {
                return new Set(value.value);
            }
            if (value?.__type === 'Date') {
                return { __type: 'Date', value: new Date(value) };
            }
            return value;
        };

        let storage: StateStorage | undefined;

        try {
            storage = getStorage();
        } catch (e) {
            return;
        }

        const persistStorage: PersistStorage<S> = {
            getItem: (name) => {
                const parse = (str: string | null) => {
                    if (str === null) {
                        return null;
                    }
                    return JSON.parse(str, reviver) as StorageValue<S>;
                };
                const str = (storage as StateStorage).getItem(name) ?? null;
                if (str instanceof Promise) {
                    return str.then(parse);
                }
                return parse(str);
            },
            setItem: (name, newValue) =>
                (storage as StateStorage).setItem(
                    name,
                    JSON.stringify(newValue, replacer)
                ),
            removeItem: (name) => (storage as StateStorage).removeItem(name),
        };

        return persistStorage;
    }
}

export default ZustandHelper;
