import ZustandHelper from "../helper/ZustandHelper";

export type UserState = {
    name: string
    setName: (name: string) => void
}

const useUser = ZustandHelper.createPersistentStore<UserState>('UserStore', (set) => ({
    name: '',
    setName: (name: string) => {
        set(() => ({
            name
        }))
    }
}))

export default useUser;
