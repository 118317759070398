export enum Page {
    HomeInv = "/:id",
    Home = "/",
    HomeQR = "/:id/:pw",
    Login = "/login",
    Invitation = "/invitation/:id",
    Admin = "/admin",
    Admin_add = "/admin/add",
    Admin_add_family = "/admin/add-family",
    Music = "/music",
    Images = "/fotos",
    ImagesBox = "/fotos/box",
    ImagesAdd = "/fotos/add",
    Videos = "/videos"
}
