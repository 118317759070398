import axios from 'axios';
import { API } from '../assets/assets';

export interface IGetFamilyById {
    family: {
        guests: IGuest[],
        id: string,
        name: string
    }
}

export interface IFamily {
    family: {
        id: string,
        name: string,
        guests?: IGuest[],
        acceptedGuests?: number,
        token?: string,
        additionalGuestCount?: number
    }
}

export interface IGuest {
    id: string,
    name: string,
    promise: boolean,
    hasAddOption: boolean,
    veggie: boolean,
    rojo_family_id: string,
    additional_guest?: IAdditionalGuest,
    rojo_additionals?: IAddAdditionalGuest[],
    updated_at?: Date,
}

export interface IAdditionalGuest {
    id: string,
    name: string,
    promise: boolean,
    veggie: boolean,
    rojo_guest_id: string
}

export interface IAddAdditionalGuest {
    name: string,
    promise: boolean,
    veggie: boolean,
    rojo_guest_id: string
}

export const getFamilyById = (id: string) => {
    return axios({
        method: 'GET',
        url: API.url + API.version + `/get-family/${id}`
    }).then((res) => {
        return res.data;
    }).catch((e) =>{
        return e;
    })
}

export const getAllFamilys = () => {
    return axios({
        method: 'GET',
        url: API.url + API.version + '/get-all-family'
    }).then((res) => {
        return res.data;
    }).catch((e) => {
        return e;
    })
}

export const addFamilyAPI = (name: string) => {
    return axios({
        method: 'POST',
        url: API.url + API.version + '/add-family',
        data: {
            name: name
        }
    }).then((res) => {
        return res.data;
    }).catch((e) => {
        return e;
    })
}

export const generateQRCodeAPI = (token: string) => {
    return axios({
        method: 'POST',
        url: API.url + API.version + `/create-qr-code/${token}`,
    }).then((res) => {
        return res.data;
    }).catch((e) => {
        return e;
    })
}
